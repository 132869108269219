import { createSlice } from '@reduxjs/toolkit'
import { loadAgreements } from './agreements.service'

const initialState = {
    agreements: [],
    status: 'idle',
    isLoaded: false,
}

const agreementsSlice = createSlice({
    name: 'agreements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadAgreements.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(loadAgreements.rejected, (state) => {
                state.status = 'rejected'
            })
            .addCase(loadAgreements.fulfilled, (state, action) => {
                state.agreements = action.payload
                state.status = 'idle'
                state.isLoaded = true
            })
    },
})

export const { actions } = agreementsSlice
export default agreementsSlice.reducer
