import axios from 'axios'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const issClient = axios.create({
    baseURL: `${process.env.REACT_APP_ISS_API_URL}`,
})

const agreementsClient = axios.create({
    baseURL: `${process.env.REACT_APP_AGREEMENTS_API_URL}`,
})

export { issClient, agreementsClient }
