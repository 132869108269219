import React from 'react'

export const paths = {
    default: '/:requestId',
    paymentSelection: '/:requestId/payment-selection',
    summary: '/:requestId/summary',
    success: '/:paymentId/success',
    pending: '/:paymentId/pending',
    error: '/:paymentId/error',
}

export const getPersonalInformationRoute = (requestId) => `/${requestId}`
export const getPaymentSelectionRoute = (requestId) => `/${requestId}/payment-selection`
export const getSummaryRoute = (requestId) => `/${requestId}/summary`

const routes = [
    {
        path: paths.default,
        element: React.lazy(() => import('./pages/PersonalInformation')),
    },
    {
        path: paths.paymentSelection,
        element: React.lazy(() => import('./pages/PaymentSelection')),
    },
    {
        path: paths.summary,
        element: React.lazy(() => import('./pages/Summary')),
    },
    {
        path: paths.success,
        element: React.lazy(() => import('./pages/Success')),
    },
    {
        path: paths.pending,
        element: React.lazy(() => import('./pages/Pending')),
    },
    {
        path: paths.error,
        element: React.lazy(() => import('./pages/Error')),
    },
]

export default routes
