import { createSlice } from '@reduxjs/toolkit'
import {
    loadFormDetails,
    loadFormPhoto,
    storePaymentMethod,
    storePersonalInformation,
} from './form.service'

const initialState = {
    requestId: null,
    data: {},
    payment: {
        id: null,
        providerId: null,
        status: null,
        url: null,
        isPaymentReady: false,
    },
    status: 'idle',
    isLoaded: false,
    photo: null,
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadFormDetails.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(loadFormDetails.rejected, (state) => {
                state.status = 'rejected'
            })
            .addCase(loadFormDetails.fulfilled, (state, action) => {
                const paymentData = action.payload.data.payment

                if (paymentData) {
                    state.payment = {
                        id: paymentData.paymentId,
                        providerId: paymentData.providerId,
                        status: paymentData.paymentStatus,
                        url: paymentData.redirectUrl,
                        isPaymentReady: !!paymentData.paymentId,
                    }
                }

                state.requestId = action.payload.requestId
                state.data = action.payload.data
                state.status = 'idle'
                state.isLoaded = true
            })
            .addCase(loadFormPhoto.fulfilled, (state, action) => {
                state.photo = action.payload
            })
            .addCase(storePersonalInformation.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(storePaymentMethod.fulfilled, (state, action) => {
                state.payment = {
                    id: action.payload.data.paymentId,
                    providerId: action.payload.providerId,
                    status: action.payload.data.paymentStatus,
                    url: action.payload.data.redirectUrl,
                    isPaymentReady: !!action.payload.data.paymentId,
                }
            })
    },
})

export const { actions } = formSlice
export default formSlice.reducer
