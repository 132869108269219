import { createAsyncThunk } from '@reduxjs/toolkit'
import { issClient } from '../../utils/HttpClients/index'
import { getResponseData } from '../../utils/HttpClients/helpers'

export const loadGlobalSettings = createAsyncThunk(
    'globalSettings/fetchSettings',
    async (requestId) => {
        const response = await issClient.get(`/request/${requestId}`).then(getResponseData)

        return response.data
    }
)

export const loadGlobalSettingsWithPaymentId = createAsyncThunk(
    'globalSettings/fetchSettingsWithPaymentId',
    async (paymentId) => {
        const response = await issClient.get(`/payment/${paymentId}`).then(getResponseData)

        return response.data
    }
)
