import React from 'react'
import './App.css'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import Layout from './containers/Layout'
import routes from './routes'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Page404 from './components/Page404'
import Loader from './components/Loader'
import ContentBlock from './components/ContentBlock'
import Helmet from './components/Helmet'

const DefaultLayout = React.lazy(() => import('./containers/Layout/DefaultLayout'))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const App = () => {
    const page500 = useSelector((state) => state.ui.page500)
    const page404 = useSelector((state) => state.ui.page404)

    if (page500.show) {
        return (
            <React.Suspense>
                <DefaultLayout>
                    <Helmet />
                    <ContentBlock isCentered>
                        <h1>{page500.message}</h1>
                    </ContentBlock>
                </DefaultLayout>
            </React.Suspense>
        )
    }

    if (page404.show) {
        // Show 404 page
    }

    return (
        <BrowserRouter>
            <SentryRoutes>
                {routes.map((route, idx) => {
                    return (
                        <Route
                            key={idx}
                            path={route.path}
                            element={
                                <Layout>
                                    <Helmet />
                                    <React.Suspense fallback={<Loader isLoading />}>
                                        <route.element />
                                    </React.Suspense>
                                </Layout>
                            }
                        />
                    )
                })}

                {/* Fallback when no route found */}
                <Route path="*" element={<Page404 />} />
            </SentryRoutes>
        </BrowserRouter>
    )
}

export default Sentry.withProfiler(App)
